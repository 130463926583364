import {useState} from "react";
import type {MenuProps} from "antd";
import {Menu} from "antd";
import {UserOutlined, UsergroupAddOutlined} from "@ant-design/icons";

import {
    BagIcon,
    ChartIcon,
    IntegrationIcon,
    UserIcon,
} from "../icon";
import {useNavigate} from "react-router-dom";

const items: MenuProps["items"] = [
    {
        label: "Home",
        key: "/",
        icon: <ChartIcon/>,
    },

    // {
    //     label: "Users",
    //     key: "user",
    //     icon: <UserIcon/>,
    //     children: [
    //         {
    //             type: "group",
    //             children: [
    //                 {
    //                     label: "All users",
    //                     key: "/users",
    //                     icon: <UserOutlined/>,
    //                 },
    //                 {
    //                     label: "Review Request",
    //                     key: "/users/pending",
    //                     icon: <UsergroupAddOutlined/>,
    //                 },
    //             ],
    //         },
    //     ],
    // },

    {
        label: "Payment Requests",
        key: "/requests",
        icon: <IntegrationIcon/>,
    },

    {
        label: "Transactions",
        key: "/transactions",
        icon: <BagIcon/>,
    },

    {
        label: "Lenco Transactions",
        key: "/lenco-transactions",
        icon: <BagIcon/>,
    },

    {
        label: "Group Savings",
        key: "/saving-groups",
        icon: <ChartIcon/>,
    },
];

function Sidebar() {
    const [current, setCurrent] = useState("mail");
    const navigate = useNavigate();
    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
        navigate(e.key);
    };
    return (
        <Menu
            className="text-[#7C8DB5]"
            onClick={onClick}
            selectedKeys={[current]}
            mode="inline"
            items={items}
        />
    );
}

export default Sidebar;
