import { Button, Tabs } from "antd";
import React from "react";
import TicketTable from "../../components/table/ticketModule/TicketTable";

function Users() {
  return (
    <section>
      <div className="flex justify-between">
        <div>
          <h2> Tickets</h2>
        </div>
        <div className="space-x-4 flex items-center">
          <Button type="default" className="rounded-md bg-black text-white">
            Generate Report
          </Button>
        </div>
      </div>

      <div className="my-10">
          <TicketTable />
      </div>
    </section>
  );
}

export default Users;
