import { Button, Tabs } from "antd";
import React from "react";
import UserActiveTable from "../../components/table/userModule/UsersTable";

function Users() {
  return (
    <section>
      <div className="flex justify-between">
        <div>
          <h2> Users</h2>
        </div>
        <div className="space-x-4 flex items-center">
          <Button type="default" className="rounded-md bg-black text-white">
            Generate Report
          </Button>
        </div>
      </div>

      <div className="my-10">
       

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="All Users" key="1">
            <UserActiveTable />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Active Users" key="2">
            <UserActiveTable />
          </Tabs.TabPane>
          <Tabs.TabPane tab="In Active Users" key="3">
            <UserActiveTable />
          </Tabs.TabPane>
        </Tabs>


      </div>
    </section>
  );
}

export default Users;
