import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom";
import Main from "./components/layout/Main";
import FormRoute from "./pages/forms/FormRoutes";
import UserRoute from "./pages/users/userRoutes";
import TicketRoute from "./pages/tickets/ticketRoutes";
import Login from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgotpassword";
import {useSelector} from "react-redux";
import Transactions from "./pages/admin/Transactions";
import PaymentRequests from "./pages/admin/PaymentRequests";
import LencoTransactions from "./pages/admin/LencoTransactions";
import GroupSavings from "./pages/admin/GroupSavings";

const PrivateRoutes = ({isAuthenticated}) => isAuthenticated ? <Outlet/> : <Navigate to='/login'/>;

function App() {
    const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

    return (
        <Router>
            <Routes>
                <Route element={<PrivateRoutes isAuthenticated={isAuthenticated}/>}>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/transactions" element={<Transactions/>}/>
                    <Route path="/lenco-transactions" element={<LencoTransactions/>}/>
                    <Route path="/requests" element={<PaymentRequests/>}/>
                    <Route path="/saving-groups" element={<GroupSavings/>}/>
                    <Route path="forms/*" element={<FormRoute/>}/>
                    <Route path="users/*" element={<UserRoute/>}/>
                    <Route path="tickets/*" element={<TicketRoute/>}/>
                </Route>

                <Route path="/login" element={<Login/>}/>
                <Route path="/forgotpassword" element={<ForgotPassword/>}/>
            </Routes>
        </Router>
    );
}

export default App;
