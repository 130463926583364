import {Avatar, Button, Dropdown, Menu, Space} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {LogoutOutlined} from "@ant-design/icons";
import {BellIcon, SearchIcon} from "../icon";
import routesTitle from "../../__mockData__/routes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {logout} from "../../redux/slice/accountSlice";
import {useEffect} from "react";

const menu = (
    <Menu
        items={[
            {
                key: "1",
                label: "Profile",
            },
        ]}
    />
);

function Header() {
    const navigate = useNavigate();
    let location = useLocation();

    const {avatar, firstName, lastName, isAuthenticated} = useSelector((state: RootState) => state.account);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated]);

    return (
        <div className="flex-shrink-0 h-20 flex justify-between items-center px-10">
            <div>
                <h1 className="font-bold text-2xl mb-0">
                    {routesTitle[location.pathname]}
                </h1>
            </div>

            <div className="space-x-8 flex items-center">
                <SearchIcon/>
                <BellIcon/>

                <div className=" space-x-3 ">
                    <Avatar src={avatar}/>
                    <Space className="cursor-pointer items-center">
                        {firstName}{lastName}
                    </Space>
                    <Button
                        onClick={() => dispatch(logout())} type="primary"
                        shape="round"
                        icon={<LogoutOutlined/>}
                        size={"large"}
                    />
                </div>
            </div>
        </div>
    );
}

export default Header;
