import React, {useEffect} from "react";
import {AdminLayout} from "../../components/layout/AdminLayout";
import {useNavigate} from "react-router-dom";
import {AppDispatch, RootState} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/slice/accountSlice";
import {getTransactions, reset} from "../../redux/slice/transactionSlice";
import {Form, Input} from "antd";
import {GenericTable} from "../../components/table/GenericTable";

function Transactions() {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {transactions, pageInfo, error} = useSelector((state: RootState) => state.transaction);
    const {token, isAuthenticated} = useSelector((state: RootState) => state.account);

    const [searchText, setSearchText] = React.useState("");

    const columns = [
        {
            title: "#",
            key: "index",
            render: (value: any, item: any, index: any) => (pageInfo.pageNumber - 1) * 10 + index + 1,
        },
        {
            title: "Transaction Reference",
            dataIndex: "transactionReference",
            key: "transactionReference",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text: string) => `₦ ${parseFloat(text).toLocaleString()}`,
        },
        {
            title: "Transaction Amount",
            dataIndex: "transactionAmount",
            key: "transactionAmount",
            render: (text: string) => {
                if (text === null) {
                    return ``
                }
                return `₦ ${parseFloat(text).toLocaleString()}`
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Transaction Action Type",
            dataIndex: "transactionActionType",
            key: "transactionActionType",
        },
        {
            title: "Transaction Type",
            dataIndex: "transactionType",
            key: "transactionType",
        },
        {
            title: "Transaction Status",
            dataIndex: "transactionStatus",
            key: "transactionStatus",
        },
        {
            title: "Initiated At",
            dataIndex: "initiatedAt",
            key: "initiatedAt",
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: "Completed At",
            dataIndex: "completedAt",
            key: "completedAt",
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: "Account Name",
            dataIndex: "accountName",
            key: "accountName",
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            key: "accountNumber",
        },
        {
            title: "Bank Name",
            dataIndex: "bankName",
            key: "bankName",
        },
        {
            title: "Account Status",
            dataIndex: "accountStatus",
            key: "accountStatus",
        },
        {
            title: "Account Created At",
            dataIndex: "accountCreatedAt",
            key: "accountCreatedAt",
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: "Account Expires At",
            dataIndex: "accountExpiresAt",
            key: "accountExpiresAt",
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: "Account Reference",
            dataIndex: "accountReference",
            key: "accountReference",
        },
        {
            title: "Group Savings",
            dataIndex: "groupSavings",
            key: "groupSavings",
        },
        {
            title: "Sub Account",
            dataIndex: "subAccount",
            key: "subAccount",
        },
    ];

    useEffect(() => {
        if (token)
            dispatch(getTransactions({
                token,
                searchParam: searchText,
                pageNumber: pageInfo?.pageNumber || 1
            })).then((res) => {
            }).catch((err) => console.log(err));

        return () => {
            // cleanup
        }
    }, [token, searchText]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (error === 'Unauthorized') {
            dispatch(reset());
            dispatch(logout());
            navigate("/login");
        }
    }, [error]);

    const handlePageChange = (pageNumber: number) => {
        dispatch(getTransactions({
            token,
            searchParam: searchText,
            pageNumber
        })).then((res) => {
        }).catch((err) => console.log(err));
    }

    return (
        <AdminLayout>
            <section className="m-10">
                <div className="my-10">
                    <Form
                        name="customized_form_controls"
                        layout="inline"
                        className="flex justify-between"
                    >
                        <Form.Item name="price" label="Search">
                            <Input
                                type="text"
                                value={""}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                className="w-[45rem]"
                            />
                        </Form.Item>
                    </Form>
                    <GenericTable
                        columns={columns}
                        data={transactions}
                        pageInfo={pageInfo}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </section>
        </AdminLayout>
    );
}

export default Transactions;
