const routesTitle: { [key: string]: string } = {
    "/": "Overview",
    "/users": "Users",
    "/users/pending": "Review New Users",
    "/reporting": "Reporting and Analysis",
    "/settings": "Settings",
    "/integrations": "Integrations",
    "/developers": "Developers",
    "/transactions": "Transactions",
    "/lenco-transactions": "Lenco Transactions",
    "/saving-groups": "Group Savings",
    "/requests": "Payment Requests",
};

export default routesTitle;
