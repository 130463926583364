import {combineReducers, configureStore} from "@reduxjs/toolkit";
import accountReducer from "./slice/accountSlice";
import liquidationReducer from "./slice/liquidationSlice";
import transactionReducer from "./slice/transactionSlice";
import groupSavingReducer from "./slice/groupSavingsSlice";
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer: any = combineReducers({
    account: accountReducer,
    liquidation: liquidationReducer,
    transaction: transactionReducer,
    groupSavings: groupSavingReducer,
});

const persistedReducer = persistReducer<any>(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;