import React, {useEffect} from "react";
import {AdminLayout} from "../../components/layout/AdminLayout";
import {GenericTable} from "../../components/table/GenericTable";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {getPaymentRequests, reset} from "../../redux/slice/liquidationSlice";
import {useNavigate} from "react-router-dom";
import {Form, Input} from "antd";
import {logout} from "../../redux/slice/accountSlice";

function PaymentRequests() {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {paymentRequests, pageInfo, error} = useSelector((state: RootState) => state.liquidation);
    const {token, isAuthenticated} = useSelector((state: RootState) => state.account);

    const [searchText, setSearchText] = React.useState("");

    const columns = [
        {
            title: "#",
            key: "index",
            render: (value: any, item: any, index: any) => (pageInfo.pageNumber - 1) * 10 + index + 1,
        },
        {
            title: "Requested User",
            dataIndex: "requestedUser",
            key: "requestedUser",
        },
        {
            title: "Request Date",
            dataIndex: "requestDate",
            key: "requestDate",
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text: string) => `₦ ${parseFloat(text).toLocaleString()}`,
        },
        {
            title: "Liquidation Status",
            dataIndex: "liqudationStatus",
            key: "liquidationStatus",
        },
    ];

    useEffect(() => {
        if (token)
            dispatch(getPaymentRequests({
                token,
                searchParam: searchText,
                pageNumber: pageInfo?.pageNumber || 1
            })).then((res) => {
            }).catch((err) => console.log(err));

        return () => {
            // cleanup
        }
    }, [token, searchText]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (error === 'Unauthorized') {
            dispatch(reset());
            dispatch(logout());
            navigate("/login");
        }
    }, [error]);

    const handlePageChange = (pageNumber: number) => {
        dispatch(getPaymentRequests({
            token,
            searchParam: searchText,
            pageNumber
        })).then((res) => {
        }).catch((err) => console.log(err));
    }

    return (
        <AdminLayout>
            <section className="m-10">
                <div className="my-10">
                    <Form
                        name="customized_form_controls"
                        layout="inline"
                        className="flex justify-between"
                    >
                        <Form.Item name="price" label="Search">
                            <Input
                                type="text"
                                value={""}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                className="w-[45rem]"
                            />
                        </Form.Item>
                    </Form>
                    <GenericTable
                        columns={columns}
                        data={paymentRequests}
                        pageInfo={pageInfo}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </section>
        </AdminLayout>
    );
}

export default PaymentRequests;
