import {IAccountState} from "../types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState: IAccountState = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    avatar: '',
    roles: [],
    token: '',
    loading: false,
    error: null,
    isAuthenticated: false
};

const apiUrl = 'https://api.payhive.ng/api';

export const getToken = createAsyncThunk("getToken", async (data: { email: string, password: string }) => {
    try {
        const response = await fetch(`${apiUrl}/Account/GetToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response?.json();
    } catch (error) {
        console.log(error)
    }
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        logout: (state) => {
            state.id = '';
            state.firstName = '';
            state.lastName = '';
            state.email = '';
            state.avatar = '';
            state.roles = [];
            state.token = '';
            state.isAuthenticated = false;
            state.error = null;
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getToken.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getToken.fulfilled, (state, action) => {
                if (!action.payload) return;

                if (action.payload.isSuccessfull === false) {
                    state.loading = false;
                    state.error = action.payload.message;
                    return;
                }

                const roles = action.payload.returnedObject.roles;
                const isSuperAdmin = roles.includes('superuser');
                if (!isSuperAdmin) {
                    state.loading = false;
                    state.error = 'You do not have permission to access this page';
                    console.log('You do not have permission to access this page');
                    return;
                }

                state.loading = false;
                state.error = null;
                state.id = action.payload.returnedObject.id;
                state.firstName = action.payload.returnedObject.firstName;
                state.lastName = action.payload.returnedObject.lastName;
                state.email = action.payload.returnedObject.email;
                state.avatar = action.payload.returnedObject.avatar;
                state.roles = action.payload.returnedObject.roles;
                state.token = action.payload.returnedObject.token;
                state.isAuthenticated = true;
            })
            .addCase(getToken.rejected, (state) => {
                state.loading = false;
                state.error = 'An error occurred';
            });
    }
});

export const {logout} = accountSlice.actions;

export default accountSlice.reducer;