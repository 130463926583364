import {ITransactionState} from "../types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState: ITransactionState = {
    transactions: null,
    lencoTransactions: null,
    loading: false,
    error: undefined,
    pageInfo: {
        pageNumber: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const apiUrl = 'https://api.payhive.ng/api';

export const getTransactions = createAsyncThunk("getTransactions", async (data: {
    token: string,
    searchParam: string,
    pageNumber: number
}) => {
    const response = await fetch(`${apiUrl}/Payment/transactions?searchParam=${data.searchParam}&pageSize=10&pageNumber=${data.pageNumber}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${data.token}`
        }
    });

    if (response.status === 401) {
        throw new Error('Unauthorized');
    }

    if (response.status !== 200) {
        throw new Error('An error occurred');
    }

    return await response?.json();
});

export const getLencoTransactions = createAsyncThunk("getLencoTransactions", async (data: {
    token: string,
    searchParam: string,
    pageNumber: number
}) => {
    const response = await fetch(`${apiUrl}/Payment/lenco-transaction?searchParam=${data.searchParam}&pageNumber=${data.pageNumber}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${data.token}`
        }
    });

    if (response.status === 401) {
        throw new Error('Unauthorized');
    }

    if (response.status !== 200) {
        throw new Error('An error occurred');
    }

    return await response?.json();
});

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        reset: (state) => {
            state.transactions = null;
            state.lencoTransactions = null;
            state.loading = false;
            state.error = undefined;
            state.pageInfo = {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTransactions.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                if (!action.payload) return;

                if (action.payload.isSuccessfull === false) {
                    state.loading = false;
                    state.error = action.payload.message;
                    return;
                }

                state.loading = false;
                state.error = undefined;
                state.transactions = action.payload?.returnedObject?.transactions.map((item: any) => {
                    return {...item, key: item.id};
                });
                state.pageInfo = action.payload?.returnedObject?.pageInfo;
            })
            .addCase(getLencoTransactions.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getLencoTransactions.fulfilled, (state, action) => {
                if (!action.payload) return;

                if (action.payload.isSuccessfull === false) {
                    state.loading = false;
                    state.error = action.payload.message;
                    return;
                }

                state.loading = false;
                state.error = undefined;
                state.lencoTransactions = action.payload?.transactions.map((item: any) => {
                    return {
                        ...item,
                        key: item.id,
                        accountName: item.details?.accountName,
                        accountNumber: item.details?.accountNumber,
                        bankName: item.details?.bank?.name,
                    };
                });
                state.pageInfo = {
                    pageNumber: action.payload?.meta?.currentPage,
                    pageSize: action.payload?.meta?.perPage,
                    totalItems: action.payload?.meta?.total,
                    totalPages: action.payload?.meta?.pageCount
                };
            })
            .addCase(getTransactions.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(getLencoTransactions.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const {reset} = transactionSlice.actions;

export default transactionSlice.reducer;