import {IGroupSavingsState} from "../types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState: IGroupSavingsState = {
    groupSavings: null,
    loading: false,
    error: undefined,
    pageInfo: {
        pageNumber: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const apiUrl = 'https://api.payhive.ng/api';

export const getGroupSavings = createAsyncThunk("getGroupSavings", async (data: {
    token: string,
    searchParam: string,
    pageNumber: number
}) => {
    const response = await fetch(`${apiUrl}/GroupSavings/report/all?searchParam=${data.searchParam}&pageSize=10&pageNumber=${data.pageNumber}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${data.token}`
        }
    });

    if (response.status === 401) {
        throw new Error('Unauthorized');
    }

    if (response.status !== 200) {
        throw new Error('An error occurred');
    }

    return await response?.json();
});

const groupSavingsSlice = createSlice({
    name: 'groupSavings',
    initialState,
    reducers: {
        reset: (state) => {
            state.groupSavings = null;
            state.loading = false;
            state.error = undefined;
            state.pageInfo = {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroupSavings.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getGroupSavings.fulfilled, (state, action) => {
                if (!action.payload) return;

                if (action.payload.isSuccessfull === false) {
                    state.loading = false;
                    state.error = action.payload.message;
                    return;
                }

                state.loading = false;
                state.error = undefined;
                state.groupSavings = action.payload?.returnedObject?.savings.map((item: any, i: number) => {
                    return {...item, key: item.id};
                });
                state.pageInfo = action.payload?.returnedObject?.pageInfo;
            })
            .addCase(getGroupSavings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const {reset} = groupSavingsSlice.actions;

export default groupSavingsSlice.reducer;