import {ILiquidationState} from "../types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState: ILiquidationState = {
    paymentRequests: null,
    loading: false,
    error: undefined,
    pageInfo: {
        pageNumber: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const apiUrl = 'https://api.payhive.ng/api';

export const getPaymentRequests = createAsyncThunk("getPaymentRequests", async (data: {
    token: string,
    searchParam: string,
    pageNumber: number
}) => {
    const response = await fetch(`${apiUrl}/GroupSavings/get-liquidation-requests?searchParam=${data.searchParam}&pageSize=10&pageNumber=${data.pageNumber}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${data.token}`
        }
    });

    if (response.status === 401) {
        throw new Error('Unauthorized');
    }

    if (response.status !== 200) {
        throw new Error('An error occurred');
    }

    return await response?.json();
});

const liquidationSlice = createSlice({
    name: 'liquidation',
    initialState,
    reducers: {
        reset: (state) => {
            state.paymentRequests = null;
            state.loading = false;
            state.error = undefined;
            state.pageInfo = {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentRequests.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getPaymentRequests.fulfilled, (state, action) => {
                if (!action.payload) return;

                if (action.payload.isSuccessfull === false) {
                    state.loading = false;
                    state.error = action.payload.message;
                    return;
                }

                state.loading = false;
                state.error = undefined;
                state.paymentRequests = action.payload?.returnedObject?.requests.map((item: any) => {
                    return {...item, key: item.id};
                });
                state.pageInfo = action.payload?.returnedObject?.pageInfo;
            })
            .addCase(getPaymentRequests.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const {reset} = liquidationSlice.actions;

export default liquidationSlice.reducer;