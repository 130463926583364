import {Outlet} from "react-router-dom";
import Navbar from "./Header";
import SideBar from "./Sidebar";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

function Main() {

    const {firstName, lastName} = useSelector((state: RootState) => state.account);
    return (
        <div className="h-screen flex overflow-y-hidden">
            <div className="flex-shrink-0 overflow-y-scroll w-64 border">
                <h2 className="text-center py-4">Admin Panel</h2>
                <div className="py-8">
                    <SideBar/>
                </div>
            </div>

            <div className="flex-1 flex flex-col flex-shrink-0  ">
                <Navbar/>
            </div>

            <div className="flex-shrink-0 overflow-y-scroll w-80 border bg-blue-800 p-6">
                <h2 className="text-white py-4">{firstName} {lastName}</h2>
                <hr/>
                <div className="py-2">
                    <h4 className="text-white mb-4">Pending Tasks</h4>

                    <Link to="#">
                        <div className="w-full bg-white shadow-lg h-[100px] p-4 rounded-md">
                            <h3 className="text-blue-800 mb-0">3 Unreplied tickets</h3>
                            <span className="text-slate-700">Abiola, Christain have questions</span>
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default Main;
