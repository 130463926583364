import {Table} from "antd";
import type {ColumnsType} from "antd/es/table";
import React from "react";
import {IPageInfo} from "../../redux/types";

interface TableProps<T> {
    columns: ColumnsType<T>;
    data: T[];
    pageInfo: IPageInfo;
    handlePageChange?: (pageNumber: number) => void;
}

export const GenericTable: React.FC<TableProps<any>> = ({columns, data, pageInfo, handlePageChange}) => {
    const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
        if (pagination?.current !== pageInfo.pageNumber) {
            if (handlePageChange) {
                handlePageChange(pagination.current)
            } else {
                console.error(`handlePageChange function is not provided. Current page number is not being handled. ${pagination.current}`);
            }
        }
    }
    return (
        <div className="mt-8">
            <Table
                rowKey="key"
                scroll={{x: "100%"}}
                columns={columns}
                dataSource={data}
                pagination={{
                    total: pageInfo?.totalItems || 0,
                    current: pageInfo?.pageNumber || 1,
                    pageSize: pageInfo?.pageSize || 10,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={tableChangeHandler}
            />
        </div>
    );
};
