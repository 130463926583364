import { Route, Routes } from "react-router-dom";
import Tickets from ".";
import ReviewTicket from "./ReviewTicket";
import SingleUserPage from "./SingleTicketPage";

export default function UserRoute() {
  return (
    <Routes>
      <Route path="/" element={<Tickets />} />
      <Route path="/:id" element={<SingleUserPage />} />
      <Route path="/pending" element={<ReviewTicket />} />
    </Routes>
  );
}
