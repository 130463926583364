import { Button, Table, Form, Select, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { useNavigate } from "react-router-dom";

interface DataType {
  key: string;
  name: string;
  email: string;
  status: string;
  funds_contributed: number;
}

const data: DataType[] = [
  {
    key: "1",
    name: "John Brown",
    email: "John Brown",
    status: "Active",
    funds_contributed: 80000
  },
];

const UserActiveTable: React.FC = () => {
  const navigate = useNavigate();
  const { Option } = Select;

  const columns: ColumnsType<DataType> = [
    {
      title: "NAMES",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "FUNDS CONTRIBUTED",
      key: "funds_contributed",
      dataIndex: "funds_contributed",
      render: (text) => <a>{text}</a>,
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => navigate(`/users/${record.key}`)} type="ghost">
          View
        </Button>
      ),
    },
  ];

  const onFinish = (values: any) => {
    console.log('Received values from form: ', values);
  };

  const onTextChange = () => {

  }

  const sortBy = () => {

  }


  return (<>
        <Form
          name="customized_form_controls"
          layout="inline"
          onFinish={onFinish}
          className="flex justify-between"
        >
            <Form.Item name="price" label="Search">
              <Input
                type="text"
                value={""}
                onChange={onTextChange}
                className="w-[45rem]"
              />
            </Form.Item>

            <Form.Item className="space-x-4 flex items-center" label="By">
              <Select
                value={""}
                style={{ width: 100, margin: '0 8px' }}
                onChange={sortBy}
              >
                <Option value="name">Name</Option>
                <Option value="email">Email</Option>
              </Select>
        </Form.Item>
      </Form>

      <div className="mt-8">
          <Table columns={columns} dataSource={data} />
      </div>
  </>);
};

export default UserActiveTable;
